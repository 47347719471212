import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";

import '../App.css';
import { useState } from "react";
import AuthService from "../services/AuthService";
import { useNavigate } from "react-router-dom";

function Login() {
    const [ username, setUsername ] = useState();
    const [ password, setPassword ] = useState();
    const Auth = new AuthService();
    const navigate = useNavigate();

    const doLogin = async() => {
        const response = await Auth.getToken(username, password);
        const token = response.data.access;
        localStorage.setItem('notarizzatore_token', token);
        navigate("/notarize");
    }

    return (
        <Container className="h-100 mt-5">
            <Row className='justify-content-center pt-5'>
                <Col sm="6" >
                    <Card className='bg-traqr py-5'>
                    <CardBody>
                        <h3 className='text-center mb-5'>Notarizzatore.IT</h3>
                        <h4 className='text-center mb-5'>Login</h4>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                name="username"
                                type="text"
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Password</Label>
                            <Input
                                name="password"
                                type="password"
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </FormGroup>
                        <Button className="mt-3 text-center" onClick={doLogin} color="light">Login</Button>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Login;