import logo from '../logo.svg';
import { Container, Row, Col, Navbar, NavbarBrand, CardBody, Card, FormGroup, Input, FormText, Spinner, Alert, Button, CardHeader } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { useEffect, useState } from 'react';
import { sha256 } from 'js-sha256';
import AuthService from '../services/AuthService';
import NotarizeService from '../services/NotarizeService';
import moment from 'moment';
import { explorer_url, domain } from '../services/env';
import { useParams } from 'react-router-dom';
import Web3 from 'web3';
import ok from '../img/ok.png'
import ko from '../img/cross.png'

function Verify() {

    const [ fileDigest, setFileDigest ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ blockData, setBlockData ] = useState({});
    const [ txData, setTxData ] = useState({});
    const [ verified, setVerified ] = useState(undefined);
    const Auth = new AuthService();
    const Nota = new NotarizeService();

    const params = useParams();

    const onFileInputChange = (event) => {
        const file = event.target.files[0];
        var reader = new FileReader();
        reader.onload = function(readed) {
          console.log(readed.target.result)
          let x = sha256.create()
          x.update(readed.target.result)
          setVerified(x.hex().toString() === fileDigest);
          console.log(x.hex().toString())
        };
        reader.readAsArrayBuffer(file);
      }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setVerified(undefined);
            const waiting = await Nota.get_tx_receipt(params.tx_id);
            const tx_details = await Nota.get_transaction(params.tx_id);
            const txreceipt = JSON.parse(tx_details.data.receipt);
            setTxData(txreceipt);
            const block = await Nota.get_block(txreceipt.blockHash);
            setBlockData(JSON.parse(block.data.receipt));
            setLoading(false);
            setFileDigest(Web3.utils.toAscii(txreceipt.input).replaceAll("\"", ""));
        }
        fetchData();
    }, []);

    return (
        <div className="App">
      <Navbar className='navbar-custom'>
        <NavbarBrand className='navbar-logo'>Notarizzatore.IT</NavbarBrand>
      </Navbar>
      <Container fluid>
        
        { loading && <Row className='my-5'>
              <Col className='text-center'>
                <Spinner color="#2E2D7D">Caricamento in corso, si prega di attendere...</Spinner>
                <h2 className='text-center'>Caricamento in corso, si prega di attendere...</h2>
              </Col>
            </Row>   
        }
        { !loading && <><Row className='py-5 justify-content-center'>
          <Col sm="10">
            <Card>
              <CardHeader className='bg-traqr'><strong>Dati Notarizzati</strong></CardHeader>
              <CardBody>
                <p><strong>File Digest:</strong> { fileDigest }</p>
                <p><strong>Transazione:</strong> <a target="_blank" href={`${explorer_url}tx/${txData.hash}`}>{ txData.hash }</a></p>
                <p><strong>Timestamp:</strong> { blockData.timestamp } ({moment.unix(blockData.timestamp).format('MMMM Do YYYY, h:mm:ss a')})</p>
                <p><strong>Block Number:</strong> { txData.blockNumber }</p>
                <p><strong>Block Hash:</strong> <a target="_blank" href={`${explorer_url}blocks/${blockData.hash}`}>{blockData.hash}</a></p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='pb-5 justify-content-center'>
          <Col sm="10" >
            <Card className='bg-traqr py-5'>
              <CardBody>
                <h3 className='text-center mb-5'>Carica il tuo file qui per verificare il dato notarizzato</h3>
                <FormGroup>
                  <Input
                    id="file-upload"
                    name="file"
                    type="file"
                    onChange={onFileInputChange}
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row></>}
        { verified !== undefined && <Row className='py-5 justify-content-center'>
          <Col sm="10" >
            <Card className='pb-5'>
              <CardBody>
              { verified ? (<div className='text-center'>
                                <img src={ok} className='img-ok py-4'/>
                                <h3 className='text-center mb-5'>Il tuo file è stato verificato correttamente</h3>
                            </div>) : (<div className='text-center'>
                                <img src={ko} className='img-ok py-4'/>
                                <h3 className='text-center mb-5'>Il tuo file è non stato verificato</h3>
                            </div>) }
              </CardBody>
            </Card>
          </Col>
        </Row> }
      </Container>
    </div>
    )
}

export default Verify;