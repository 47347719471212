import { Container, Row, Col, Navbar, NavbarBrand, CardBody, Card, FormGroup, Input, FormText, Spinner, Alert, Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { useState } from 'react';
import { sha256 } from 'js-sha256';
import AuthService from '../services/AuthService';
import NotarizeService from '../services/NotarizeService';
import moment from 'moment';
import { explorer_url, domain } from '../services/env';


function Notarize() {

  const [ fileDigest, setFileDigest ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ blockData, setBlockData ] = useState({});
  const [ txData, setTxData ] = useState({});
  const [ step, setStep ] = useState("");
  const [isLoaded, setIsLoaded ] = useState(false);
  const Auth = new AuthService();
  const Nota = new NotarizeService();

  const onFileInputChange = (event) => {
    const file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function(readed) {
      console.log(readed.target.result)
      let x = sha256.create()
      x.update(readed.target.result)
      setFileDigest(x.hex());
      notarize(x.hex());
    };
    reader.readAsArrayBuffer(file);
  }

  const notarize = async(digest) => {
    setLoading(true);
    setIsLoaded(false);
    setStep("Sto inviando i dati alla blockchain...");
    
    const transact = await Nota.notarize(digest);
    setStep("Attendo la creazione del blocco...");
    const waiting = await Nota.get_tx_receipt(transact.data.txid);
    const tx_details = await Nota.get_transaction(transact.data.txid);
    const txreceipt = JSON.parse(tx_details.data.receipt);
    setTxData(txreceipt);
    const block = await Nota.get_block(txreceipt.blockHash);
    setBlockData(JSON.parse(block.data.receipt));
    setLoading(false);
    setIsLoaded(true);
  }

  const openPDF = () => {
    window.open(`${domain}api/v1/blockchain/get_tx_pdf/${txData.hash}/`, '_blank');
  }

  return (
    <div className="App">
      <Navbar className='navbar-custom'>
        <NavbarBrand className='navbar-logo'>Notarizzatore.IT</NavbarBrand>
      </Navbar>
      <Container fluid>
        <Row className='py-5 justify-content-center'>
          <Col sm="10" >
            <Card className='bg-traqr py-5'>
              <CardBody>
                <h3 className='text-center mb-5'>Carica il tuo file qui per ottenere il tuo certificato di notarizzazione</h3>
                <FormGroup>
                  <Input
                    id="file-upload"
                    name="file"
                    type="file"
                    onChange={onFileInputChange}
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        { loading && <Row>
              <Col className='text-center'>
                <Spinner color="#2E2D7D">Caricamento in corso, si prega di attendere...</Spinner>
                <h2 className='text-center'>{ step }</h2>
              </Col>
            </Row>   
        }
        { isLoaded && <Row className='py-5 justify-content-center'>
          <Col sm="10">
            <Card>
              <CardBody>
                <Alert color="success">Complimenti! Il tuo documento è stato notarizzato correttamente.</Alert>
                <p><strong>File Digest:</strong> { fileDigest }</p>
                <p><strong>Transazione:</strong> <a target="_blank" href={`${explorer_url}tx/${txData.hash}`}>{ txData.hash }</a></p>
                <p><strong>Timestamp:</strong> { blockData.timestamp } ({moment.unix(blockData.timestamp).format('MMMM Do YYYY, h:mm:ss a')})</p>
                <p><strong>Block Number:</strong> { txData.blockNumber }</p>
                <p><strong>Block Hash:</strong> <a target="_blank" href={`${explorer_url}blocks/${blockData.hash}`}>{blockData.hash}</a></p>
                <p className='text-center'><Button onClick={openPDF} size="lg" color='success'>Scarica il certificato</Button></p>
              </CardBody>
            </Card>
          </Col>
        </Row>}
      </Container>
    </div>
  );
}

export default Notarize;
