import axios from "axios";
import { domain } from "./env";

export default class NotarizeService {

    constructor() {
        this.notarize = this.notarize.bind(this);
        this.pdf = this.pdf.bind(this);
        this.get_block = this.get_block.bind(this);
        this.get_transaction = this.get_transaction.bind(this);
        this.get_tx_receipt = this.get_tx_receipt.bind(this);
    }


    notarize = async(hash) => {
        const token = localStorage.getItem('notarizzatore_token')
        return axios.post(domain + `api/v1/blockchain/send_transaction/`, {
                'content': hash
            }, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        }
        );
    }

    pdf = async(tx_hash) =>{
        const token = localStorage.getItem('notarizzatore_token')
        return axios.get(domain + `api/v1/blockchain/pdf/${tx_hash}/`, { 
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
    }

    get_tx_receipt = async(tx_hash) => {
        const token = localStorage.getItem('notarizzatore_token')
        return axios.get(domain + `api/v1/blockchain/get_tx_receipt/${tx_hash}/`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
    }

    get_transaction = async(tx_hash) =>{
        const token = localStorage.getItem('notarizzatore_token')
        return axios.get(domain + `api/v1/blockchain/get_tx/${tx_hash}/`, { 
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
    }

    get_block = async(block_number) => {
        const token = localStorage.getItem('notarizzatore_token')
        return axios.get(domain + `api/v1/blockchain/get_block/${block_number}/`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
    }

}