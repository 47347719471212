import axios from "axios";
import { domain } from "./env";
export default class AuthService {

    constructor() {
        this.getToken = this.getToken.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
    }

    getToken = async(username, password) => {
        return axios.post(domain + "accounts/token/", {
            username: username,
            password: password
        });
    }

    isLoggedIn() {
        const token = localStorage.getItem('notarizzatore_token');
        return token;
    }
}