import { Route, Navigate, useLocation } from 'react-router-dom';
import AuthService from './AuthService';

export default function RequireAuth({ children }) {
    const Auth = new AuthService();
    const isLoggedIn = Auth.isLoggedIn()
    let location = useLocation();
  
    if (!isLoggedIn) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/" state={{ from: location }} />;
    }
  
    return children;
}